import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';

// assets

// services

// props
import { IEmployeeContractJobdeskResourceShortProps } from '../../../../../../props/employees/contracts/jobdesk';

// components
import { ActionButton, DefaultButton, DetailsList, IconButton, Panel, PanelType, PrimaryButton, SelectionMode, Stack, TextField } from '@fluentui/react';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';
import Tag from '../../../../../uiframeworks/tag';
import GeneralService from '../../../../../../services/general';
import { IEmployeePayslipDeductionResourceShortProps } from '../../../../../../props/employees/payslips/deduction';

type EmployeePayslipDeductionFormProps = {
    deductions: IEmployeePayslipDeductionResourceShortProps[];
    onChange(deductions: IEmployeePayslipDeductionResourceShortProps[]): void;
}

const EmployeePayslipDeductionForm: React.FC<EmployeePayslipDeductionFormProps> = (props: EmployeePayslipDeductionFormProps) => {
    const [deductions, setDeductions] = React.useState<IEmployeePayslipDeductionResourceShortProps[]>(props.deductions);
    const [surfaceMode, setSurfaceMode] = React.useState<'add' | 'update' | undefined>();
    const [activeDeduction, setActiveDeduction] = React.useState<IEmployeePayslipDeductionResourceShortProps | undefined>();

    useEffect(() => {
        setDeductions(props.deductions);
    }, [props.deductions]);

    return <Stack tokens={{ childrenGap: 10 }}>
        <Stack horizontal tokens={{ childrenGap: 20 }} horizontalAlign={'space-between'} verticalAlign={'center'}>
            <Label size={'small'}>Deductions</Label>
            <DefaultButton text={'Add deduction'} iconProps={{ iconName: "Add" }} onClick={() => {
                setSurfaceMode('add');
                setActiveDeduction({
                    id: GeneralService.guid(),
                    name: "",
                    description: "",
                    amount: ""
                })
            }} />
        </Stack>
        {deductions.length < 1 ? <Text size={'small'} style={{ fontStyle: 'italic' }}>No deductions on this payslip</Text> : null}
        {deductions.length > 0 ? <Stack tokens={{ childrenGap: 10 }}>
            <DetailsList items={deductions}
                isHeaderVisible={false}
                compact={true}
                selectionMode={SelectionMode.none}
                cellStyleProps={{ cellLeftPadding: 0, cellExtraRightPadding: 0, cellRightPadding: 0 }}
                columns={[
                    {
                        key: 'idx',
                        name: 'idx',
                        minWidth: 20,
                        maxWidth: 20,
                        isPadded: false,
                        onRender: (item: IEmployeePayslipDeductionResourceShortProps, idx?: number) => {
                            return <Stack styles={{ root: { padding: '2px 0px' } }}>
                                <Text size={'small'}>{(idx || 0) + 1}.</Text>
                            </Stack>
                        }
                    },
                    {
                        key: 'details',
                        name: 'Details',
                        minWidth: 100,
                        isPadded: false,
                        onRender: (item: IEmployeePayslipDeductionResourceShortProps) => {
                            return <Stack styles={{ root: { padding: '2px 0px' } }}>
                                <Label size={'xsmall'}>{item.name}</Label>
                                {(item.description || "").trim() !== "" ? <Text size={'xsmall'}>{item.description}</Text> : null}
                            </Stack>
                        }
                    },
                    {
                        key: 'amount',
                        name: 'Amount',
                        minWidth: 140,
                        maxWidth: 140,
                        isPadded: false,
                        onRender: (item: IEmployeePayslipDeductionResourceShortProps) => {
                            return <Stack styles={{ root: { padding: '2px 0px', marginRight: 10 } }}>
                                <Text size={'small'} style={{textAlign: 'right'}} className={'color-red'}>+ Rp. {GeneralService.getNumberWithSeparator(Number(item.amount))}</Text>
                            </Stack>
                        }
                    },
                    {
                        key: 'actions',
                        name: '',
                        minWidth: 65,
                        maxWidth: 65,
                        isPadded: false,
                        onRender: (item: IEmployeePayslipDeductionResourceShortProps) => {
                            return <Stack>
                                <Stack horizontal tokens={{ childrenGap: 2 }} horizontalAlign={'end'} className={"detailsListActionRow"}>
                                    <ActionButton className={'detailsListActionButton'} styles={{ root: { paddingLeft: '4px !important', paddingRight: '4px !important' } }} iconProps={{ iconName: 'Edit' }} onClick={() => {
                                        setSurfaceMode('update');
                                        setActiveDeduction({ ...item });
                                    }} />
                                    <ActionButton className={'detailsListActionButton'} styles={{ root: { paddingLeft: '4px !important', paddingRight: '4px !important' } }} iconProps={{ iconName: 'Delete' }} onClick={() => {
                                        if (window.confirm('Are you sure to remove deduction "' + item.name + '"?')) {
                                            const _deductions = deductions.filter((d) => d.id !== item.id);
                                            props.onChange(_deductions);
                                        }
                                    }} />
                                </Stack>
                            </Stack>
                        }
                    }
                ]} />
        </Stack> : null}
        <Panel headerText={surfaceMode === 'add' ? "Add Deduction" : "Update Deduction"}
            isOpen={activeDeduction !== undefined}
            type={PanelType.medium}
            onDismiss={() => {
                setSurfaceMode(undefined);
                setActiveDeduction(undefined);
            }}
            isFooterAtBottom={true}
            onRenderFooterContent={() => {
                return <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <PrimaryButton text={surfaceMode === 'add' ? "Add" : "Update"}
                        disabled={(activeDeduction?.name || "") === "" || (activeDeduction?.name || "").length > 100 || (activeDeduction?.description || "").length > 2000 || (activeDeduction?.amount || "").trim() === ""}
                        onClick={() => {
                            if (activeDeduction) {
                                const _deductions = deductions;
                                const idx = _deductions.findIndex((b) => b.id === activeDeduction.id);

                                if (idx > -1) {
                                    _deductions[idx] = activeDeduction;
                                } else {
                                    _deductions.push(activeDeduction);
                                }

                                props.onChange(_deductions);
                                setActiveDeduction(undefined);
                            }
                        }} />
                    <DefaultButton text={"Cancel"} onClick={() => {
                        setSurfaceMode(undefined);
                        setActiveDeduction(undefined);
                    }} />
                </Stack>
            }}>
            {activeDeduction ? <>
                <Stack tokens={{ childrenGap: 20 }}>
                    <TextField label={"Name"}
                        required
                        onChange={(evt, value) => {
                            if (activeDeduction) {
                                setActiveDeduction({ ...activeDeduction, name: value || "" });
                            }
                        }}
                        value={activeDeduction.name} />
                    <TextField label={"Description"}
                        multiline
                        rows={5}
                        autoAdjustHeight
                        resizable={false}
                        onChange={(evt, value) => {
                            if (activeDeduction) {
                                setActiveDeduction({ ...activeDeduction, description: value || "" });
                            }
                        }}
                        value={activeDeduction.description || ""} />
                    <TextField label={"Amount"}
                        prefix={'Rp'}
                        onChange={(evt, value) => {
                            if (activeDeduction && (value || "").trim() === "" || !isNaN(Number(value))) {
                                setActiveDeduction({ ...activeDeduction, amount: value || "" });
                            }
                        }}
                        value={activeDeduction.amount || ""} />
                </Stack>
            </> : null}
        </Panel>
    </Stack >
};

export default EmployeePayslipDeductionForm;
