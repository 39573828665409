import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faExclamationTriangle, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../../services/general/error';

// props

// components
import { DefaultButton, Dropdown, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import Text from '../../../../typography/text';
import SelectEmployee from '../../../../uiframeworks/forms/selectEmployee';
import GeneralService from '../../../../../services/general';
import ValidationService from '../../../../../services/validation';
import UserService from '../../../../../services/users/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IEmployeePayslipResourceProps } from '../../../../../props/employees/payslips/payslip';
import EmployeePayslipService from '../../../../../services/employees/payslip';
import Label from '../../../../typography/label';
import EmployeePayslipBonusForm from './subforms/bonus';
import EmployeePayslipDeductionForm from './subforms/deduction';
import Details from '../../../../typography/details';
import SelectEmployeeContract from '../../../../uiframeworks/forms/employees/contract';
import moment from 'moment';
import SelectSubsidiary from '../../../../uiframeworks/forms/selectSubsidiary';

type EmployeePayslipFormProps = {
    payslipId?: string;
    employeeId?: string;
    onDismissed(refresh?: boolean, item?: IEmployeePayslipResourceProps): void;
}

const EmployeePayslipForm: React.FC<EmployeePayslipFormProps> = (props: EmployeePayslipFormProps) => {
    const { banner, route, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [data, setData] = React.useState<Partial<IEmployeePayslipResourceProps>>({
        id: GeneralService.makeid(25, true, 'PSL'),
        payslipMonth: moment().format("MM/YYYY")
    });
    const [error, setError] = React.useState<any>({});
    const mode: 'create' | 'update' = props.payslipId === undefined ? 'create' : 'update';

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        let _data = data;

        if (props.payslipId) {
            const _payslip = await EmployeePayslipService.read(props.payslipId);

            _data = {
                id: _payslip.id,
                payslipMonth: _payslip.payslipMonth,
                employee: _payslip.employee,
                contract: _payslip.contract,
                salaryAmount: _payslip.salaryAmount,
                healthInsuranceAmount: _payslip.healthInsuranceAmount,
                workplaceInsuranceAmount: _payslip.workplaceInsuranceAmount,
                ppnAmount: _payslip.ppnAmount,
                pphAmount: _payslip.pphAmount,
                subtotalAmount: _payslip.subtotalAmount,
                totalAmount: _payslip.totalAmount,
                bonuses: _payslip.bonuses,
                deductions: _payslip.deductions
            };
        } else {
            if (props.employeeId) {
                const _employee = await UserService.get(props.employeeId);
                _data.employee = _employee;
            }
        }

        setData({ ..._data });
        setLoaded(true);
    }

    const isSubmitButtonDisabled = (): boolean => {
        if (!data.employee || !data.contract) {
            return true;
        } else if ((data.healthInsuranceAmount || "").trim() === "" || (data.workplaceInsuranceAmount || "").trim() === "") {
            return true;
        } else if ((data.ppnAmount || "").trim() === "" || (data.pphAmount || "").trim() === "") {
            return true;
        }

        return false;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);

            const fd = new FormData();
            fd.append('id', data.id || "");
            fd.append('payslipMonth', data.payslipMonth || "");
            fd.append('employeeId', data.employee?.id || "");
            fd.append('contractId', data.contract?.id || "");
            fd.append('subsidiaryId', data.subsidiary?.id || "");
            fd.append('salaryAmount', (data.contract ? data.contract.salary : data.salaryAmount || "") + "");
            fd.append('healthInsuranceAmount', data.healthInsuranceAmount || "");
            fd.append('workplaceInsuranceAmount', data.workplaceInsuranceAmount || "");
            fd.append('ppnAmount', data.ppnAmount || "");
            fd.append('pphAmount', data.pphAmount || "");
            fd.append('subtotalAmount', data.subtotalAmount || "");
            fd.append('totalAmount', data.totalAmount || "");

            (data.bonuses || []).forEach((bonus) => {
                fd.append('bonuses[]', JSON.stringify(bonus));
            });

            (data.deductions || []).forEach((deduction) => {
                fd.append('deductions[]', JSON.stringify(deduction));
            });

            let result;
            if (props.payslipId === undefined) {
                result = await EmployeePayslipService.create(fd);
            } else {
                result = await EmployeePayslipService.update(props.payslipId, fd);
            }

            props.onDismissed(true, result);
        } catch (e) {
            banner.add({
                key: 'create_payslip_error',
                variant: 'error',
                icon: faXmarkCircle,
                text: `Failed to create payslip. Error: ${ErrorService.getMessage(e)}`
            });

            setSubmitting(false);
        }
    }

    const calculateTotalAmount = (_data: Partial<IEmployeePayslipResourceProps>) => {
        let {salaryAmount, healthInsuranceAmount, workplaceInsuranceAmount, ppnAmount, pphAmount, contract, bonuses, deductions} = _data;

        let subtotal = 0;
        let total = 0;

        if (contract) {
            salaryAmount = contract.salary + "";
        }

        if ((salaryAmount || "").trim() !== "") {
            subtotal += Number(salaryAmount);
        }

        if ((healthInsuranceAmount || "").trim() !== "") {
            subtotal -= Number(healthInsuranceAmount);
        }

        if ((workplaceInsuranceAmount || "").trim() !== "") {
            subtotal -= Number(workplaceInsuranceAmount);
        }

        (bonuses || []).forEach((bonus) => {
            subtotal += Number(bonus.amount);
        });

        (deductions || []).forEach((deduction) => {
            subtotal -= Number(deduction.amount);
        });

        total = subtotal;        

        if ((ppnAmount || "").trim() !== "") {
            total -= Number(ppnAmount);
        }

        if ((pphAmount || "").trim() !== "") {
            total -= Number(pphAmount);
        }

        return {total: total.toFixed(2), subtotal: subtotal.toFixed(2)};
    }

    return <Panel headerText={mode === 'create' ? "Create Payslip" : "Update Payslip"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal verticalAlign={'center'} horizontalAlign={'space-between'} tokens={{ childrenGap: 10 }}>
                {
                    !submitting ? <>
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled()} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </Stack>
                        <Details title={'Total (aft. tax)'} text={`Rp. ${GeneralService.getNumberWithSeparator(Number(data.totalAmount || "0"))}`} />
                    </> : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={mode === 'create' ? "Creating payslip ..." : "Updating payslip ..."} /> : null}
            </Stack>;
        }}>
        <Stack tokens={{ childrenGap: 20 }}>
            {!loaded ? <Stack horizontalAlign={"baseline"}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded ? <>
                    <Stack tokens={{ childrenGap: 20 }}>
                        <TextField label={"Payslip number"}
                            prefix={'#'}
                            value={data.id}
                            disabled />
                        <Stack horizontal tokens={{childrenGap: 15}}>
                            <Dropdown label={"Payment month"} selectedKey={moment(data.payslipMonth, "MM/YYYY").format("MM")} options={[
                                {key: '01', text: 'January'},
                                {key: '02', text: 'February'},
                                {key: '03', text: 'March'},
                                {key: '04', text: 'April'},
                                {key: '05', text: 'May'},
                                {key: '06', text: 'June'},
                                {key: '07', text: 'July'},
                                {key: '08', text: 'August'},
                                {key: '09', text: 'September'},
                                {key: '10', text: 'October'},
                                {key: '11', text: 'November'},
                                {key: '12', text: 'December'}
                            ]} styles={{root: {width: '100%'}}}
                            onChange={(ev, opt) => {
                                const month = opt ? opt.key as string : moment(data.payslipMonth, "MM/YYYY").format("MM");
                                const year = moment(data.payslipMonth, "MM/YYYY").format("YYYY");
                                data.payslipMonth = `${month}/${year}`;

                                setData({ ...data });
                                setError({ ...error });
                            }} />
                            <Dropdown label={"Year"} selectedKey={moment(data.payslipMonth, "MM/YYYY").format("YYYY")} options={[
                                {key: '2024', text: '2024'},
                                {key: '2025', text: '2025'},
                                {key: '2026', text: '2026'},
                                {key: '2027', text: '2027'},
                                {key: '2028', text: '2028'},
                                {key: '2029', text: '2029'},
                                {key: '2030', text: '2030'},
                            ]} styles={{root: {width: 125}}}
                            onChange={(ev, opt) => {
                                const year = opt ? opt.key as string : moment(data.payslipMonth, "MM/YYYY").format("YYYY");
                                const month = moment(data.payslipMonth, "MM/YYYY").format("MM");
                                data.payslipMonth = `${month}/${year}`;

                                setData({ ...data });
                                setError({ ...error });
                            }} />
                        </Stack>
                        <Stack className={"divider"}></Stack>
                        <SelectEmployee label={'Select employee'}
                            required={true}
                            selected={data.employee}
                            errorMessage={error.employee}
                            disabled={submitting || props.employeeId !== undefined}
                            onChange={(employee) => {
                                data.employee = employee;

                                const validation = ValidationService.combination(employee, ['required'], {});
                                error.employee = validation.message;

                                if (data.employee && data.employee.subsidiaries && data.employee.subsidiaries.length === 1) {
                                    data.subsidiary = data.employee.subsidiaries[0];
                                }

                                setData({ ...data });
                                setError({ ...error });
                            }} />
                        <SelectEmployeeContract label={'Select contract'}
                            required
                            selected={data.contract}
                            employeeId={data.employee?.id || ""}
                            waiting={data.employee === undefined}
                            onChange={(contract) => {
                                data.contract = contract;

                                const validation = ValidationService.combination(contract, ['required'], {});
                                error.contract = validation.message;

                                if (data.contract && data.contract.subsidiary) {
                                    data.subsidiary = data.contract.subsidiary;
                                }

                                setData({ ...data });
                                setError({ ...error });
                            }} />
                        <SelectSubsidiary label={'Select subsidiary'}
                            required
                            selected={data.subsidiary}
                            disabled={!data.employee || data.contract !== undefined}
                            options={data.employee ? data.employee.subsidiaries : (data.contract ? [data.contract.subsidiary] : [])}
                            onChange={(subsidiary) => {
                                data.subsidiary = subsidiary;

                                const validation = ValidationService.combination(subsidiary, ['required'], {});
                                error.subsidiary = validation.message;

                                setData({ ...data });
                                setError({ ...error });
                            }} />
                        <Stack className={"divider"}></Stack>
                        <TextField label={'Base salary'}
                            prefix={'Rp'}
                            required
                            errorMessage={error.salaryAmount}
                            disabled={data.contract !== undefined}
                            value={data.contract ? GeneralService.getNumberWithSeparator(data.contract.salary) : data.salaryAmount}
                            onChange={(evt, value) => {
                                if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                    const _data = data;
                                    _data.salaryAmount = value || "";

                                    const {total, subtotal} = calculateTotalAmount(_data);
                                    _data.totalAmount = total;
                                    _data.subtotalAmount = subtotal;

                                    const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 20 });
                                    error.salaryAmount = validation.message;

                                    setData({ ...data });
                                    setError({ ...error });
                                }
                            }} />
                        <Stack className={"divider"}></Stack>
                        <Stack tokens={{childrenGap: 10}}>
                            <Stack horizontal tokens={{ childrenGap: 8 }}>
                                <FontAwesomeIcon icon={faExclamationTriangle} className={'color-yellow'} style={{ position: 'relative', top: 1 }} />
                                <Text size={'small'} className={'color-yellow'}>Amount for both insurances are only amount that is borned by the employee. This will act as a deduction to employee's salary.</Text>
                            </Stack>
                            <Stack horizontal tokens={{ childrenGap: 15 }}>
                                <TextField label={'BPJS Kesehatan'}
                                    prefix={'Rp'}
                                    required
                                    errorMessage={error.healthInsuranceAmount}
                                    value={data.healthInsuranceAmount}
                                    styles={{ root: { width: '50%' } }}
                                    onChange={(evt, value) => {
                                        if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                            const _data = data;
                                            _data.healthInsuranceAmount = value || "";

                                            const {total, subtotal} = calculateTotalAmount(_data);
                                            _data.totalAmount = total;
                                            _data.subtotalAmount = subtotal;
                
                                            setData({...data});

                                            const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 20 });
                                            error.healthInsuranceAmount = validation.message;

                                            setData({ ...data });
                                            setError({ ...error });
                                        }
                                    }} />
                                <TextField label={'BPJS Ketenagakerjaan'}
                                    prefix={'Rp'}
                                    required
                                    errorMessage={error.workplaceInsuranceAmount}
                                    value={data.workplaceInsuranceAmount}
                                    styles={{ root: { width: '50%' } }}
                                    onChange={(evt, value) => {
                                        if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                            const _data = data;
                                            _data.workplaceInsuranceAmount = value || "";

                                            const {total, subtotal} = calculateTotalAmount(_data);
                                            _data.totalAmount = total;
                                            _data.subtotalAmount = subtotal;

                                            const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 20 });
                                            error.workplaceInsuranceAmount = validation.message;

                                            setData({ ...data });
                                            setError({ ...error });
                                        }
                                    }} />
                            </Stack>
                        </Stack>
                        <Stack className={"divider"}></Stack>
                        <Stack tokens={{childrenGap: 10}}>
                            <Stack horizontal tokens={{ childrenGap: 8 }}>
                                <FontAwesomeIcon icon={faExclamationTriangle} className={'color-yellow'} style={{ position: 'relative', top: 1 }} />
                                <Text size={'small'} className={'color-yellow'}>Amount for both ppn and pph are only amount that is borned by the employee. This will act as a deduction to employee's salary.</Text>
                            </Stack>
                            <Stack horizontal tokens={{ childrenGap: 15 }}>
                                <TextField label={'PPn'}
                                    prefix={'Rp'}
                                    required
                                    errorMessage={error.ppnAmount}
                                    value={data.ppnAmount}
                                    styles={{ root: { width: '50%' } }}
                                    onChange={(evt, value) => {
                                        if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                            const _data = data;
                                            _data.ppnAmount = value || "";

                                            const {total, subtotal} = calculateTotalAmount(_data);
                                            _data.totalAmount = total;
                                            _data.subtotalAmount = subtotal;
                
                                            setData({...data});

                                            const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 20 });
                                            error.ppnAmount = validation.message;

                                            setData({ ...data });
                                            setError({ ...error });
                                        }
                                    }} />
                                <TextField label={'PPh'}
                                    prefix={'Rp'}
                                    required
                                    errorMessage={error.pphAmount}
                                    value={data.pphAmount}
                                    styles={{ root: { width: '50%' } }}
                                    onChange={(evt, value) => {
                                        if ((value || "").trim() === "" || !isNaN(Number(value))) {
                                            const _data = data;
                                            _data.pphAmount = value || "";

                                            const {total, subtotal} = calculateTotalAmount(_data);
                                            _data.totalAmount = total;
                                            _data.subtotalAmount = subtotal;

                                            const validation = ValidationService.combination(value, ['required', 'limit'], { maxChars: 20 });
                                            error.pphAmount = validation.message;

                                            setData({ ...data });
                                            setError({ ...error });
                                        }
                                    }} />
                            </Stack>
                        </Stack>
                        <Stack className={"divider"}></Stack>
                        <EmployeePayslipBonusForm bonuses={data.bonuses || []} onChange={(bonuses) => {
                            const _data = data;
                            _data.bonuses = bonuses || [];

                            const {total, subtotal} = calculateTotalAmount(_data);
                            _data.totalAmount = total;
                            _data.subtotalAmount = subtotal;

                            setData({...data});
                        }} />
                        <Stack className={"divider"}></Stack>
                        <EmployeePayslipDeductionForm deductions={data.deductions || []} onChange={(deductions) => {
                            const _data = data;
                            _data.deductions = deductions || [];

                            const {total, subtotal} = calculateTotalAmount(_data);
                            _data.totalAmount = total;
                            _data.subtotalAmount = subtotal;

                            setData({...data});
                        }} />
                    </Stack>
                    <Stack className={"divider"}></Stack>
                    <Details title={'Subtotal (before tax)'} text={`Rp. ${GeneralService.getNumberWithSeparator(Number(data.subtotalAmount || "0"))}`} />
                    <Details title={'Total (after tax)'} text={`Rp. ${GeneralService.getNumberWithSeparator(Number(data.totalAmount || "0"))}`} />
                </> : null
            }
        </Stack>
    </Panel>
};

export default EmployeePayslipForm;
