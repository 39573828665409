import React, { useEffect } from 'react';
import { useStore } from '../../../../stores/root';

// assets

// services
import ExternalBankAccountService from '../../../../services/data/bankaccounts/external';

// props
import { IExternalBankAccountResourceShortProps } from '../../../../props/data/bankaccounts/external';

// components
import { Dropdown, Link, SpinnerSize, Stack } from '@fluentui/react';
import Label from '../../../typography/label';
import Text from '../../../typography/text';
import LoadingComponent from '../../../feedbacks/loading';
import ExternalBankAccountForm from '../../../data/bankaccounts/external/form';
import { IBankAccountResourceShortProps } from '../../../../props/bankaccounts';
import BankAccountService from '../../../../services/bankaccounts';
import BankAccountForm from '../../../bankaccounts/general/form';
import { IEmployeeContractResourceShortProps } from '../../../../props/employees/contracts/contract';
import EmployeeContractService from '../../../../services/employees/contracts';
import GeneralService from '../../../../services/general';
import Tag from '../../tag';

type SelectEmployeeContractProps = {
    employeeId: string;
    selected?: IEmployeeContractResourceShortProps;
    options?: IEmployeeContractResourceShortProps[];
    qs?: string[];
    label?: string;
    hideLabel?: boolean;
    required?: boolean;
    disabled?: boolean;
    allowCreate?: boolean;
    waiting?: boolean;
    onChange(selected?: IEmployeeContractResourceShortProps): void;
}

const SelectEmployeeContract: React.FC<SelectEmployeeContractProps> = (props: SelectEmployeeContractProps) => {
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [selected, setSelected] = React.useState<IEmployeeContractResourceShortProps | undefined>();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [waiting, setWaiting] = React.useState<boolean>(false);
    const [options, setOptions] = React.useState<IEmployeeContractResourceShortProps[]>([]);

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    useEffect(() => {
        init();
    }, [props.waiting]);

    useEffect(() => {
    }, [props]);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if (!props.waiting) {
            setLoaded(false);
            setWaiting(false);

            const qs: string[] = [];
            if (props.employeeId) { qs.push(`employee_id=${props.employeeId}`) }

            const results = await EmployeeContractService.retrieve(qs.join("&"));
            setOptions(results.data);
            setLoaded(true);
        } else {
            setLoaded(true);
            setWaiting(true);
        }
    }

    return <Stack>
        {props.hideLabel ? null : <Label style={{ padding: '3px 0px' }} size={'xsmall'} required={props.required}>{props.label || "Bank account"}</Label>}
        <Stack tokens={{ childrenGap: 3 }}>
            <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign={'center'} styles={{ root: { width: '100%' } }}>
                {!loaded ? <LoadingComponent label={""} size={SpinnerSize.small} /> : null}
                <Stack styles={{ root: { width: '100%' } }}>
                    <Dropdown disabled={props.disabled || !loaded || waiting}
                        selectedKey={selected?.id}
                        options={options.map((contract) => {
                            return { key: contract.id, text: `#${contract.id}`, metadata: contract }
                        })}
                        styles={{
                            dropdownItem: {
                                paddingTop: "5px !important",
                                paddingBottom: "5px !important",
                                borderBottom: "1px solid #eee",
                                height: "unset !important"
                            },
                            dropdownItemSelected: {
                                height: "unset !important"
                            }
                        }}
                        onRenderOption={(option: any) => {
                            if (option) {
                                const data = option.metadata as IEmployeeContractResourceShortProps;
                                return <Stack tokens={{ childrenGap: 3 }} styles={{root: {width: '100%'}}}>
                                    <Stack horizontal verticalAlign={'center'} styles={{ root: { width: '100%' } }} tokens={{ childrenGap: 10 }} horizontalAlign={'space-between'}>
                                        <Label size={'xsmall'}>#{data.id}</Label>
                                        {data.status === 'Active' ? <Tag size={'small'} text={data.status} variant={'success'} /> : null}
                                        {data.status === 'Pending Approval' ? <Tag size={'small'} text={data.status} variant={'warning'} /> : null}
                                        {data.status === 'Cancelled' || data.status === 'Terminated' || data.status === 'Expired' ? <Tag size={'small'} text={data.status} variant={'error'} /> : null}
                                    </Stack>
                                    <Text style={{ marginTop: -2 }} size={'small'}>Rp. {GeneralService.getNumberWithSeparator(data.salary)}</Text>
                                </Stack>
                            } else {
                                return null;
                            }
                        }}
                        onChange={(evt, opt) => {
                            const selected = options.find((o) => o.id === opt?.key as string);
                            props.onChange(selected);
                        }} />
                </Stack>
            </Stack>
        </Stack>
    </Stack>;
};

export default SelectEmployeeContract;
