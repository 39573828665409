import React, { useEffect } from 'react';

// assets

// services
import ErrorService from '../../../../../services/general/error';

// props

// components
import { ActionButton, CommandBar, DefaultButton, ICommandBarItemProps, Stack } from '@fluentui/react';
import { useStore } from '../../../../../stores/root';
import { faCheckCircle, faHome, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import { IEmployeePayslipResourceProps } from '../../../../../props/employees/payslips/payslip';
import EmployeePayslipService from '../../../../../services/employees/payslip';
import moment from 'moment';
import LoadingComponent from '../../../../feedbacks/loading';
import DetailsStack, { IStackSectionProps } from '../../../../uiframeworks/detailsStack';
import EmployeePayslipDetailsProperties from './subdetails/properties';
import EmployeePayslipDetailsSalary from './subdetails/salary';
import PermissionsService from '../../../../../services/permissions';
import EmployeePayslipDetailsBonuses from './subdetails/bonuses';
import EmployeePayslipDetailsDeductions from './subdetails/deductions';
import EmployeePayslipForm from '../form';
import OutcomePaymentForm from '../../../../finances/outcomes/payments/general/form';
import Tag from '../../../../uiframeworks/tag';
import EmployeePayslipDetailsPayments from './subdetails/payments';

type EmployeePayslipDetailsProps = {
    payslipId: string;
}

const EmployeePayslipDetails: React.FC<EmployeePayslipDetailsProps> = (props: EmployeePayslipDetailsProps) => {
    const { banner, topbar, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [payslip, setPayslip] = React.useState<IEmployeePayslipResourceProps | undefined>();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [hasPermission, setHasPermission] = React.useState<boolean>(false);

    useEffect(() => {
        _onGetPayslip();
    }, []);

    const _onGetPayslip = async () => {
        try {
            setLoaded(false);

            const _payslip = await EmployeePayslipService.read(props.payslipId);
            setPayslip(_payslip);

            topbar.show("Payslip - " + moment(_payslip.payslipMonth, 'MM/YYYY').format("MMMM YYYY"), [
                { key: "", icon: faHome, href: "/" },
                { key: 'employees', text: 'Employees' },
                { key: '/users/administrators/' + _payslip.employee.id, text: _payslip.employee.name },
                { key: 'payslips', text: 'Payslips' },
                { key: props.payslipId, text: "#" + _payslip.id }
            ]);

            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'get_payslip_error',
                text: 'Failed to get payslip details. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const getCommandBarItems = () => {
        const items: ICommandBarItemProps[] = [];
        const farItems: ICommandBarItemProps[] = [];

        if (PermissionsService.hasPermission(['outcomes.invoices.payments.request'], user.permissions)) {
            items.push({
                key: 'update',
                onRender: () => {
                    return <DefaultButton text={'Request payment'} styles={{root: {marginRight: 10}}} onClick={() => setActiveSurface('payments.request')} />
                }
            });
        }

        if (PermissionsService.hasPermission(['employees.payslips.update.all'], user.permissions)) {
            items.push({
                key: 'update',
                onRender: () => {
                    return <ActionButton text={'Update details'} iconProps={{iconName: "Edit"}} styles={{root: {marginRight: 10}}} onClick={() => setActiveSurface('payslip.update')} />
                }
            });
        }

        if (PermissionsService.hasPermission(['employees.payslips.delete'], user.permissions)) {
            farItems.push({
                key: 'delete',
                onRender: () => {
                    return <ActionButton text={'Delete payslip'} iconProps={{iconName: "Delete"}} />
                }
            });
        }

        return {items, farItems};
    }

    const leftSections = (_payslip: IEmployeePayslipResourceProps) => {
        const sections: IStackSectionProps[] = [];

        sections.push({
            key: 'detail',
            isCard: true,
            header: {title: 'Payslip Details'},
            content: <EmployeePayslipDetailsProperties payslip={_payslip} />
        });

        const tabs: any = [];
        if ((_payslip.bonuses || []).length > 0) {
            tabs.push({
                key: 'bonuses',
                title: 'Bonuses',
                content: <EmployeePayslipDetailsBonuses payslip={_payslip} />
            });
        }

        if ((_payslip.deductions || []).length > 0) {
            tabs.push({
                key: 'deductions',
                title: 'Deductions',
                content: <EmployeePayslipDetailsDeductions payslip={_payslip} />
            });
        }

        sections.push({
            key: 'tabs',
            isCard: true,
            tabs
        });

        return sections;
    }

    const rightSections = (_payslip: IEmployeePayslipResourceProps) => {
        const sections: IStackSectionProps[] = [];

        sections.push({
            key: 'status',
            content: <>
                {Number(_payslip.unpaidAmount) <= 0 ? <Tag variant={'success'} icon={faCheckCircle} text={'Fully paid'} /> : null}
                {Number(_payslip.unpaidAmount) > 0 ? <Tag variant={'warning'} text={'Pending payment'} /> : null}
            </>
        });

        if ((_payslip.payments || []).length > 0) {
            sections.push({
                key: 'payments',
                isCard: true,
                content: <EmployeePayslipDetailsPayments payslip={_payslip} />
            });
        }

        sections.push({
            key: 'salary',
            isCard: true,
            header: {title: 'Salary Breakdown'},
            content: <EmployeePayslipDetailsSalary payslip={_payslip} />
        });

        return sections;
    }

    const _onSurfaceDismissed = (refresh?: boolean, item?: IEmployeePayslipResourceProps) => {
        setActiveSurface(undefined);
        if (refresh) {_onGetPayslip()}
    }

    return <Stack tokens={{ childrenGap: 20 }}>
        {!loaded ? <LoadingComponent label='Retrieving payslip details ...' labelPosition='right' spinnerPosition='baseline' /> : null}
        {loaded && payslip ? <>
            <CommandBar
                items={getCommandBarItems().items}
                farItems={getCommandBarItems().farItems}
                styles={{
                    root: {
                        padding: 0,
                        height: 'unset',
                        backgroundColor: 'transparent'
                    }
                }}
                ariaLabel="Use left and right arrow keys to navigate between commands" />
            <Stack tokens={{ childrenGap: 20 }}>
                <DetailsStack left={leftSections(payslip)} right={rightSections(payslip)} />
            </Stack>
            {activeSurface === 'payslip.update' ? <EmployeePayslipForm payslipId={payslip.id} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'payments.request' ? <OutcomePaymentForm payslipId={payslip.id} onDismissed={_onSurfaceDismissed} /> : null}
        </> : null}
    </Stack>;
};

export default EmployeePayslipDetails;
