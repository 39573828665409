import { IEmployeeContractResourceProps, IEmployeeContractResourceShortProps } from '../../props/employees/contracts/contract';
import { IPaginationResourceShort } from '../../props/general';
import Request from '../request';

const EmployeeContractService = {
  retrieve: async (qs?: string): Promise<{ data: IEmployeeContractResourceShortProps[], pagination: IPaginationResourceShort }> => {
    try {
      const results = await Request.get(`/api/employees/contracts?${qs || ''}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  read: async (id: string): Promise<IEmployeeContractResourceProps> => {
    try {
      const results = await Request.get(`/api/employees/contracts/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  create: async (data: FormData): Promise<IEmployeeContractResourceProps> => {
    try {
      const result = await Request.post(`/api/employees/contracts`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  update: async (id: string, data: FormData): Promise<IEmployeeContractResourceProps> => {
    try {
      const result = await Request.post(`/api/employees/contracts/${id}?_method=PUT`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  terminate: async (id: string, data: FormData): Promise<void> => {
    try {
      await Request.post(`/api/employees/contracts/${id}/terminate`, data);
      return;
    } catch (e) {
      throw e;
    }
  },
  cancel: async (id: string): Promise<void> => {
    try {
      await Request.post(`/api/employees/contracts/${id}/cancel`);
      return;
    } catch (e) {
      throw e;
    }
  },
  approval: {
    hr: async (id: string): Promise<IEmployeeContractResourceProps> => {
      try {
        const result = await Request.post(`/api/employees/contracts/${id}/hr_approval`, (new FormData()), 'multipart/form-data');
        return result;
      } catch (e) {
        throw e;
      }
    },
    legal: async (id: string): Promise<IEmployeeContractResourceProps> => {
      try {
        const result = await Request.post(`/api/employees/contracts/${id}/legal_approval`, (new FormData()), 'multipart/form-data');
        return result;
      } catch (e) {
        throw e;
      }
    },
    reset: async (id: string): Promise<IEmployeeContractResourceProps> => {
      try {
        const result = await Request.post(`/api/employees/contracts/${id}/reset_approval`, (new FormData()), 'multipart/form-data');
        return result;
      } catch (e) {
        throw e;
      }
    }
  },
  sign: {
    company: async (id: string, data: FormData): Promise<IEmployeeContractResourceProps> => {
      try {
        const result = await Request.post(`/api/employees/contracts/${id}/company_sign`, data, 'multipart/form-data');
        return result;
      } catch (e) {
        throw e;
      }
    },
    employee: async (id: string): Promise<IEmployeeContractResourceProps> => {
      try {
        const result = await Request.post(`/api/employees/contracts/${id}/employee_sign`, (new FormData()), 'multipart/form-data');
        return result;
      } catch (e) {
        throw e;
      }
    }
  }
};

export default EmployeeContractService;
